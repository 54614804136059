document.querySelectorAll('figure.video--thumbnail--container').forEach(thumbnail => {
    thumbnail.addEventListener('click', () => {
        media(thumbnail)
    })
})

document.querySelectorAll('button.video--button').forEach(button => {
    button.addEventListener('click', () => {
        media(button)
    })
})

const media = (el) => {
    const body = document.querySelector('body')
    const id = el.getAttribute('data-trigger-id')
    const videoContainer = el.parentElement.querySelector('div.video--container[data-id="' + id + '"]')
    const video = videoContainer.querySelector('video')

    body.style.overflow = 'hidden'
    videoContainer.classList.add('active')
    video.currentTime = 0
    video.play()

    videoContainer.querySelector('button#videoClose').addEventListener('click', () => {
        body.style.overflow = ''
        videoContainer.classList.remove('active')
        video.pause()
    })
}