import Swiper from "swiper";
import { Navigation, Autoplay, A11y } from "swiper";
import { initNeosEventItemCreated } from "../../Assets/Scripts/neosEvents";
import { initNeosEventItemSelected } from "../../Assets/Scripts/neosEvents";

export function historySwiper() {
    document.querySelectorAll(".history--swiper").forEach((slider) => {
        const isNeosBackend = document.body.classList.contains('neos-backend')
        
        const swiper = new Swiper(slider, {
            modules: [Navigation, Autoplay, A11y],
            allowTouchMove: !isNeosBackend,
            slidesPerView: "auto",
            spaceBetween: 0,
            watchOverflow: true,
            resizeObserver: true,
            observer: true,
            observeParents: true,
            observeSlideChildren: true,
            reverseDirection: true,
            navigation: {
                nextEl: ".swiper-navigation-next",
                prevEl: ".swiper-navigation-prev",
            },
        });

        const number = parseInt((slider.querySelector(".swiper-slide").getAttribute("aria-label")).split(" / ")[1]);
        swiper.slideTo(number);

        initNeosEventItemCreated(swiper, slider);
        initNeosEventItemSelected(swiper, slider);
    });
}

historySwiper()