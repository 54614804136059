import Swiper from "swiper";
import { Navigation, Pagination, A11y } from "swiper";
import { initNeosEventItemCreated } from "../../../Assets/Scripts/neosEvents";
import { initNeosEventItemSelected } from "../../../Assets/Scripts/neosEvents";

export function stageMatrixSwiper() {
    document.querySelectorAll(".stagematrix--swiper").forEach((slider) => {
        const isNeosBackend = document.body.classList.contains('neos-backend')
        
        const swiperItems = Array.from(slider.querySelectorAll('.swiper-slide'))
        if(swiperItems.length <= 1) {
            return
        }
        
        const swiper = new Swiper(slider, {
            modules: [Pagination, Navigation, A11y],
            allowTouchMove: !isNeosBackend,
            slidesPerView: 1,
            spaceBetween: 30,
            watchOverflow: true,
            resizeObserver: true,
            observer: true,
            observeParents: true,
            observeSlideChildren: true,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + "</span>";
                },
            },
            navigation: {
                nextEl: ".swiper-navigation-next",
                prevEl: ".swiper-navigation-prev",
            },
        });

        const pointContainer = document.querySelector('.matrix--points')
        const points = pointContainer.querySelectorAll('.matrix--point')

        //First Item is active
        const firstSlideData = slider.querySelector('.matrix--slide').getAttribute('data-id')
        pointContainer.querySelector('[data-trigger-id="' + firstSlideData + '"]').classList.add('active')

        //Observation for Classchange
        slider.querySelectorAll('.matrix--slide').forEach((slide) => {
            onClassChange(slide, changeClass);
        });

        function onClassChange(node, callback) {
            let lastClassString = node.classList.toString();

            const mutationObserver = new MutationObserver((mutationList) => {
            for (const item of mutationList) {
                if (item.attributeName === "class") {
                const classString = node.classList.toString();
                if (classString !== lastClassString) {
                    callback(mutationObserver);
                    lastClassString = classString;
                    break;
                }
                }
            }
            });

            mutationObserver.observe(node, { attributes: true });
            return mutationObserver;
        }

        function changeClass() {
            const activeSlideData = document.querySelector('.swiper-slide-active').getAttribute('data-id')

            points.forEach((point) => {
                point.classList.remove('active')
                const pointData = point.getAttribute('data-trigger-id')

                if(pointData == activeSlideData) {
                    point.classList.add('active')
                }

            })
        }

        // Change Slider by PointInteraction
        points.forEach((point, index) => {
            point.addEventListener('click', () => {
                const pointData = point.getAttribute('data-trigger-id')
                const slide = slider.querySelector('[data-id="' + pointData + '"]')

                if (!slide.classList.contains('swiper-slide-active')) {
                    swiper.slideTo(index);
                }
            });
        });

        initNeosEventItemCreated(swiper, slider);
        initNeosEventItemSelected(swiper, slider);
    });
}

stageMatrixSwiper()