const body = document.querySelector('body')
const toggleMenu = document.querySelector('.toggle--burger')
const navigationDefault = document.querySelector('.navigation--header')
const navPointsA = navigationDefault.querySelectorAll('a.navigation--listitem--link, a.subnavigation--listitem--link')
const navPointsSpan = navigationDefault.querySelectorAll('span.navigation--listitem--link')


// Close Navigation
const closeNavigation = () => {
    toggleMenu.classList.remove('active')
    navigationDefault.classList.remove('active')
    body.classList.remove('no-scroll')
}


// Navigation ActiveState
const getState = (item) => {
    const pathnames = location.pathname.split('/').slice(1)
    const pathKey = item.getAttribute('data-name')
    const isActive = pathnames.includes(pathKey)

    if(isActive) {
        item.parentElement.classList.add('active')
    }
}

navPointsA.forEach(item => {
    getState(item);
    item.addEventListener('click', () => {
        closeNavigation()
    })
})


navPointsSpan.forEach(item => {
    getState(item);

    item.addEventListener('click', () => {
        window.value = document.querySelector('body').getBoundingClientRect().top

        //!!! Deactivate All Navigationitems and SubNavigation
        //Navigation Item
        navigationDefault.querySelectorAll('.navigation--listitem').forEach(item => {
            if(item.classList.contains('active')) {
                item.classList.remove('active')

                if(item.querySelector('.subnavigation'))
                item.querySelector('.subnavigation').classList.remove('active')
            }
        })

        //!!! Activate Navigationitem and SubNavigation
        //Item
        item.closest('.navigation--listitem').classList.add('active')
        //Subnavigation
        item.closest('.navigation--listitem.active').querySelector('.subnavigation').classList.add('active')
    })
})

const adjustCurrent = () => {
    navigationDefault.querySelectorAll('.navigation--listitem--link').forEach(item => {
        getState(item)
    })
}

// Open/Close Navigation Mobile
toggleMenu.addEventListener('click', () => {
    toggleMenu.classList.toggle('active')
    navigationDefault.classList.toggle('active')
    body.classList.toggle('no-scroll')
})


//!!! Close SubNavigation
//Click back on mobile
document.querySelectorAll('.subnavigation--back span').forEach(item => {
    item.addEventListener('click', () => {
        item.closest('.subnavigation').classList.remove('active')
        item.closest('.navigation--listitem').classList.remove('active')

        adjustCurrent()
    })
})

// Click outside
window.addEventListener('click', event => {
    navigationDefault.querySelectorAll('.subnavigation.active').forEach(item => {

        if (!document.querySelector('header').contains(event.target)) {
            item.classList.remove('active')
            item.closest('.navigation--listitem').classList.remove('active')

            adjustCurrent()
        }
    })
})

// Scroll
window.addEventListener('scroll', () => {
    navigationDefault.querySelectorAll('.subnavigation.active').forEach(item => {
        if(item) {
            if(window.innerWidth >= 900) {
                const documentCurrentPosition = document.querySelector('body').getBoundingClientRect().top
                const difference = window.value + documentCurrentPosition * -1

                if (difference >= 200 || difference <= -200) {
                    item.classList.remove('active')
                    item.closest('.navigation--listitem').classList.remove('active')

                    adjustCurrent()
                }
            }
        }
    })
})