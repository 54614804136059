export function UploadField(el, optionsOverwrite) {
    // Standardoptionen
    const defaultOptions = {
        selectorItem: '.field--upload',
        selectorLabel: '.field--upload-button',
        selectorInput: '.field--upload-input',
        selectorFileList: '.field--upload-filelist',
        selectorFileTemplate: '.field--upload-filetemplate',
        selectorFileName: '.field--upload-filename',
        selectorFileSize: '.field--upload-filesize'
    };

    if(document.querySelector('.field--upload')) {
        // Zusammenführen der Optionen
        const options = Object.assign({}, defaultOptions, optionsOverwrite);

        // DOM-Elemente
        const fileUpload = el.querySelector(options.selectorInput);
        const selectedFilesList = el.querySelector(options.selectorFileList);
        const fileTemplate = el.querySelector(options.selectorFileTemplate);

        // Event Listener hinzufügen
        fileUpload.addEventListener('change', function(event) {
            const files = event.target.files;
            selectedFilesList.innerHTML = ''; // Liste leeren

            if (files) {
                for (let i = 0; i < files.length; i++) {
                    const clone = document.importNode(fileTemplate.content, true);
                    const fileNameElement = clone.querySelector(options.selectorFileName);
                    const fileSizeElement = clone.querySelector(options.selectorFileSize);

                    fileNameElement.textContent = files[i].name;
                    fileSizeElement.textContent = ' (' + formatFileSize(files[i].size) + ')';

                    selectedFilesList.appendChild(clone);
                }
            }
        });

        // Funktion zur Formatierung der Dateigröße
        function formatFileSize(bytes) {
            if (bytes < 1024) return bytes + ' Bytes';
            else if (bytes < 1048576) return (bytes / 1024).toFixed(2) + ' KB';
            else return (bytes / 1048576).toFixed(2) + ' MB';
        }
    }
}

// Instanziierung
if(document.querySelector('.field--upload')) {
    const element = document.querySelector('.field--upload');
    const uploadFieldInstance = new UploadField(element);
}
