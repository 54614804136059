import './Template/_Fragment/Header/Navigation/Navigation.js'
import './Assets/Scripts/neosEvents.js'

import './Template/_Fragment/StageImageContainer/StageImageContainer.js'
import './Template/_Fragment/StageMatrixContainer/StageMatrixContainer.js'
import './Template/_Fragment/StageMatrixContainer/StageMatrixContainer.js'
import './Component/HistorySlider/HistorySlider.js'
import './Component/TextMediaSlider/TextMediaSlider.js'
import './Component/Slider/Slider.js'

import './Template/_Fragment/Footer/Navigation/Navigation.js'
import './Component/_Fragment/Media/Media.js'
import './Component/Accordion/Accordion.js'
import './Component/FormContainer/Fragments/Upload/Upload.js'
import './Component/FormContainer/Form.js'
import './Component/TextMediaExpandable/TextMediaExpandable.js'
import './Assets/Scripts/ButtonUp.js'

import { stageImageSwiper } from './Template/_Fragment/StageImageContainer/StageImageContainer.js'
import { stageMatrixSwiper } from './Template/_Fragment/StageMatrixContainer/StageMatrixContainer.js'
import { textMediaSwiper } from './Component/TextMediaSlider/TextMediaSlider.js'
import { historySwiper } from './Component/HistorySlider/HistorySlider.js'
import { singleSwiper } from './Component/Slider/Slider.js'

document.addEventListener('Neos.NodeCreated', function(event) {
    const element = event.detail.element;

    if(element.querySelector('.stageimage--swiper')) {
        stageImageSwiper()
    }
    if(element.querySelector('.stagematrix-slide')) {
        stageMatrixSwiper()
    }
    if(element.querySelector('.textmedia--swiper')) {
        textMediaSwiper()
    }
    if(element.querySelector('.history-swiper')) {
        historySwiper()
    }
    if(element.querySelector('.single-swiper')) {
        singleSwiper()
    }
})