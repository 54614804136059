const navigationFooter = document.querySelector('.navigation--footer')
const navPointsFooter = navigationFooter.querySelectorAll('a.footer--listitem--link')


const getFooterState = (item) => {
    const pathnames = location.pathname.split('/').slice(1)
    const pathKey = item.getAttribute('data-name')
    const isActive = pathnames.includes(pathKey)

    if(isActive) {
        item.parentElement.classList.add('active')
    }
}

navPointsFooter.forEach(item => {
    getFooterState(item);
})

if(window.location.pathname == '/' || window.location.pathname == '/en') {
    document.querySelector('footer .home--listitem').classList.add('active')
}