import {UploadField} from "./Fragments/Upload/Upload.js";

if (document.querySelector('form')) {
    // Default options
    const defaultOptions = {
        selectorFieldDownload: '.field--upload',
        selectorButtonSubmit: '.form--submit'
    };

    function initializeApplicationForm(formElement, optionsOverwrite = {}) {
        // Merge default options with provided options
        const options = Object.assign({}, defaultOptions, optionsOverwrite);

        const buttonSubmit = formElement.querySelector(options.selectorButtonSubmit);
        const fileUploadElements = Array.from(formElement.querySelectorAll(options.selectorFieldDownload));

        // Add event listener for form submission
        formElement.addEventListener('submit', function(e) {
            deactivateSubmitButton(buttonSubmit);
        });

        // Initialize uploads
        if(document.querySelector('.field--upload')) {
            fileUploadElements.forEach(function(fileUpload) {
                // Assuming UploadField is a function in the vanilla JS context.
                UploadField(fileUpload);
            });
        }
    }

    function deactivateSubmitButton(button) {
        button.disabled = true;
    }

    // Usage
    const form = document.querySelector('form');
    initializeApplicationForm(form);
}

