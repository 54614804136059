document.addEventListener('DOMContentLoaded', function() {
    var button = document.querySelector('.button--up');
    if (!button) return;  // Wenn der Button nicht existiert, beenden

    // Funktion, um den Zustand des Buttons zu aktualisieren
    function updateButtonVisibility() {
        if (window.scrollY > (window.innerHeight * 0.5)) {
            button.classList.add('active');
        } else {
            button.classList.remove('active');
        }
    }

    // Button Zustand aktualisieren, wenn die Seite geladen wird
    updateButtonVisibility();

    // Button Zustand aktualisieren, wenn gescrollt wird
    window.addEventListener('scroll', updateButtonVisibility);
});